.centered-container {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    height: 100vh; /* Make container full height of the viewport */
  }
  
  .centered-content {
    font-family: sans-serif;
    text-align: center; /* Center text horizontally */
  }
  

.error {
    color: red!important;
    padding: 0px!important;
    margin: 0px!important;
}

.error_padding {
    padding-top: 15px!important;
}

.sent_message {
    padding-top: 20px!important;
    font-size: 1.5rem;
    margin: 0px!important;
}
